import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const SourcesLogos = () => {
  const {
    cdcImage,
    oshaImage,
    acoemImage,
    njhealthImage,
  } = useStaticQuery(graphql`
    query {
      cdcImage: file(relativePath: { eq: "sources/cdc.png" }) {
        childImageSharp {
          fluid(maxWidth: 225) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      oshaImage: file(relativePath: { eq: "sources/osha.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 225) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      acoemImage: file(relativePath: { eq: "sources/acoem.png" }) {
        childImageSharp {
          fluid(maxWidth: 225) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      njhealthImage: file(relativePath: { eq: "nj-health.png" }) {
        childImageSharp {
          fluid(maxWidth: 225) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <div className="flex gap-5 sm:gap-10 items-center justify-center bg-white">
      <div className="w-32">
        <Img fluid={cdcImage.childImageSharp.fluid} alt="CDC" />
      </div>
      <div className="w-32">
        <Img fluid={oshaImage.childImageSharp.fluid} alt="OSHA" />
      </div>
      <div className="w-32">
        <Img fluid={acoemImage.childImageSharp.fluid} alt="ACOEM" />
      </div>
      <div className="w-32">
        <Img
          fluid={njhealthImage.childImageSharp.fluid}
          alt="NJ Department of Health"
        />
      </div>
    </div>
  )
}

export default SourcesLogos
