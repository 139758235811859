import React from "react"
import GuidanceDate from "./guidance-date"
import SourcesLogos from "./sources-logos"
import { OutboundLink } from "gatsby-plugin-gtag"

const Sources = () => {
  return (
    <div className="prose max-w-none">
      <h2>Detailed Sources</h2>

      <p>
        <GuidanceDate /> Please confirm guidance at the links provided below.
      </p>

      <SourcesLogos />

      <p className="italic">
        This PPE Information is intended to be used as a general guide for
        businesses. The PPE needs of your particular business and employees may
        vary significantly and each business is responsible for making its own
        decisions about those needs. All users should consult the links provided
        herein for the latest official health information, as the PPE Planning
        Tool summaries may not accurately reflect the current content of those
        official health links or the latest updates thereto. The State of New
        Jersey and the New Jersey Economic Development Authority (collectively,
        the “State”) is not responsible for the performance or accuracy of the
        PPE Planning Tool. By using the PPE Planning Tool, the user does hereby
        release any and all claims, causes of action, and demands against the
        State that are derived from its use.
      </p>

      <div className="grid grid-cols-2 gap-x-5">
        <div>
          <h3>CDC</h3>
          <ul>
            <li>
              <OutboundLink
                rel="noreferrer noopener"
                href="https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/about-face-coverings.html"
              >
                How to select, wear, and clean your mask
              </OutboundLink>
            </li>
            <li>
              <OutboundLink
                rel="noreferrer noopener"
                href="https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/cloth-face-cover-guidance.html"
              >
                Considerations for wearing masks
              </OutboundLink>
            </li>
            <li>
              <OutboundLink
                rel="noreferrer noopener"
                href="https://www.cdc.gov/handwashing/show-me-the-science-hand-sanitizer.html"
              >
                When &amp; How to Use Hand Sanitizer in Community Settings
              </OutboundLink>
            </li>
            <li>
              <OutboundLink
                rel="noreferrer noopener"
                href="https://www.cdc.gov/coronavirus/2019-ncov/hcp/respirator-use-faq.html"
              >
                Healthcare worker PPE Q&amp;A
              </OutboundLink>
            </li>
            <li>
              <OutboundLink
                rel="noreferrer noopener"
                href="https://www.cdc.gov/coronavirus/2019-ncov/community/organizations/meat-poultry-processing-workers-employers.html"
              >
                Meat and poultry processing
              </OutboundLink>
            </li>
            <li>
              <OutboundLink
                rel="noreferrer noopener"
                href="https://www.cdc.gov/coronavirus/2019-ncov/community/guidance-manufacturing-workers-employers.html"
              >
                Manufacturing workers and employers
              </OutboundLink>
            </li>
          </ul>
        </div>

        <div>
          <h3>OSHA</h3>
          <ul>
            <li>
              <OutboundLink
                rel="noreferrer noopener"
                href="https://www.osha.gov/Publications/OSHA3990.pdf"
              >
                Guidance on Preparing Workplaces for COVID-19
              </OutboundLink>
            </li>
            <li>
              <OutboundLink
                rel="noreferrer noopener"
                href="https://www.osha.gov/SLTC/covid-19/covid-19-faq.html"
              >
                COVID-19 FAQ
              </OutboundLink>
            </li>
            <li>
              <OutboundLink
                rel="noreferrer noopener"
                href="https://www.osha.gov/SLTC/covid-19/controlprevention.html#interim"
              >
                Industry specific guidance
              </OutboundLink>
            </li>
            <li>
              <OutboundLink
                rel="noreferrer noopener"
                href="https://www.osha.gov/SLTC/heatstress/covid-19-cloth-coverings-outdoor-heat.pdf"
              >
                Cloth face coverings in hot and humid environments outdoors
              </OutboundLink>
            </li>
            <li>
              <OutboundLink
                rel="noreferrer noopener"
                href="https://www.osha.gov/SLTC/heatstress/covid-19-cloth-coverings-indoor-heat.pdf"
              >
                Cloth face coverings in hot and humid environments indoors
              </OutboundLink>
            </li>
          </ul>
        </div>

        <div>
          <h3>
            American College of Occupational and Environmental Medicine COVID-19
            Resource Center
          </h3>
          <ul>
            <li>
              <OutboundLink
                rel="noreferrer noopener"
                href="https://acoem.org/COVID-19-Resource-Center/COVID-19-Q-A-Forum/Question-Index"
              >
                COVID Q&amp;A Forum
              </OutboundLink>
            </li>
            <li>
              <OutboundLink
                rel="noreferrer noopener"
                href="https://acoem.org/COVID-19-Resource-Center/COVID-19-Q-A-Forum/Could-you-provide-guidance-on-the-use-of-plexiglass-barriers-for-workplaces-for-sneeze-guard%E2%80%9D-dropl"
              >
                Q&amp;A on sneeze guards
              </OutboundLink>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Sources
