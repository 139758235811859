import React from "react"
import { Link } from "gatsby"
import Logo from "../images/nj-logo.svg"
import Seal from "../images/nj-seal.svg"
import { OutboundLink } from "gatsby-plugin-gtag"

const Header = ({ siteTitle, state, langKey }) => {
  return (
    <header className="flex flex-col bg-white print:hidden">
      <div className="bg-black px-4 sm:px-2">
        <div className="container h-16 py-1 flex justify-between items-center">
          <div className="flex flex-grow h-full content-center items-center">
            <img className="h-10 mr-3" src={Seal} alt="Seal of New Jersey" />
            <span className="text-white uppercase text-sm">
              Official site of the state of {state}
            </span>
          </div>
          <Link
            className="text-sm underline text-white pr-5"
            to={langKey !== "en" ? "/" : "/es"}
          >
            {langKey !== "en" ? "English" : "En Español"}
          </Link>
          <span className="text-white text-sm">
            Return to{" "}
            <OutboundLink
              className="underline text-white"
              href="https://nj.gov"
            >
              nj.gov
            </OutboundLink>
          </span>
        </div>
      </div>

      <div className="container md:flex justify-between pt-6 px-4 sm:px-2">
        <h1 className="flex text-base mb-4">
          <OutboundLink href="http://covid19.nj.gov/" className="no-underline h-16 flex items-center">
            <img className="h-16 mr-6" src={Logo} alt="New Jersey logo" />
            <div>
              <div className="font-bold text-green-400 uppercase leading-tight">
                {state}
              </div>
              <div className="font-bold text-njblue text-xl leading-tight">
                {siteTitle}
              </div>
            </div>
          </OutboundLink>
        </h1>

        <div className="text-sm">
          <div>
            <strong className="font-bold">
              Call (General COVID-19 Questions)
            </strong>
            : <a href="tel://211">2-1-1</a> (24/7)
          </div>
          <div>
            <strong className="font-bold">
              Call (Medical COVID-19 Questions)
            </strong>
            : <a href="tel://+18009621253">1-800-962-1253</a> (24/7)
          </div>
          <div>
            <strong className="font-bold">
              Text NJCOVID to <a href="sms:898211?&amp;body=NJCOVID">898-211</a>
            </strong>{" "}
            to receive alerts
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header
