import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { OutboundLink } from "gatsby-plugin-gtag"

import githubImage from "../images/social/github.svg"
import twitterImage from "../images/social/twitter.svg"
import facebookImage from "../images/social/facebook.svg"
import heartImage from "../images/heart.svg"
import Header from "./header"
import Sources from "./sources"

const Layout = ({ children, hideSources = false, langKey = "en" }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          state
        }
      }

      opraImage: file(relativePath: { eq: "opra.png" }) {
        childImageSharp {
          fixed(width: 165) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }

      njhealthImage: file(relativePath: { eq: "nj-health.png" }) {
        childImageSharp {
          fixed(width: 165) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `)

  const { opraImage, njhealthImage } = data

  return (
    <>
      <Header
        siteTitle={data.site.siteMetadata.title}
        state={data.site.siteMetadata.state}
        langKey={langKey}
      />

      <div className="bg-white px-4 sm:px-2">
        <main className="container lg:max-w-4xl mx-auto p-2 pb-10 print:p-0">
          {children}
          {!hideSources && (
            <div className="mt-12 print:hidden">
              <Sources />
            </div>
          )}
        </main>
      </div>

      <footer className="print:hidden text-white">
        <div className="bg-gray-600">
          <div className="container mx-auto py-4 px-4 sm:px-2">
            <div className="flex justify-between items-center gap-5">
              <div className="flex flex-col lg:flex-row gap-5 items-center">
                <OutboundLink
                  href="https://nj.gov/opra/"
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                >
                  <Img
                    fixed={opraImage.childImageSharp.fixed}
                    alt="OPRA: Open Public Records Act"
                  />
                  <span className="sr-only"> Link Opens in New Tab</span>
                </OutboundLink>
                <OutboundLink
                  href="https://www.nj.gov/health/"
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                >
                  <Img
                    fixed={njhealthImage.childImageSharp.fixed}
                    alt="New Jersey Department of Health"
                  />
                  <span className="sr-only"> Link Opens in New Tab</span>
                </OutboundLink>
              </div>

              <div className="text-xs text-right">
                <div className="flex justify-end gap-2 mb-2">
                  <OutboundLink
                    href="https://twitter.com/NJGov"
                    target="_blank"
                    rel="nofollow noopener noreferrer"
                  >
                    <img
                      className="not-sr-only h-8 w-8"
                      alt="Twitter: @NJGov"
                      src={twitterImage}
                    />
                    <span className="sr-only wcag-new-tab-hover">
                      {" "}
                      Link Opens in New Tab
                    </span>
                  </OutboundLink>
                  <OutboundLink
                    href="http://www.facebook.com/NJGov"
                    target="_blank"
                    rel="nofollow noopener noreferrer"
                  >
                    <img
                      className="not-sr-only h-8 w-8"
                      alt="Facebook: NJGovook"
                      src={facebookImage}
                    />
                    <span className="sr-only wcag-new-tab-hover">
                      {" "}
                      Link Opens in New Tab
                    </span>
                  </OutboundLink>
                  <OutboundLink
                    href="https://github.com/newjersey/join"
                    target="_blank"
                    rel="nofollow noopener noreferrer"
                  >
                    <img
                      className="not-sr-only h-8 w-8"
                      alt="GitHub: New Jersey Office of Innovation + Lab"
                      src={githubImage}
                    />
                    <span className="sr-only wcag-new-tab-hover">
                      {" "}
                      Link Opens in New Tab
                    </span>
                  </OutboundLink>
                </div>
                <div>
                  <OutboundLink
                    href="https://public.govdelivery.com/accounts/NJGOV/subscriber/new"
                    target="_blank"
                    rel="nofollow noopener noreferrer"
                    className="text-white"
                  >
                    Sign up for Email Updates
                    <span className="sr-only"> Link Opens in New Tab</span>
                  </OutboundLink>{" "}
                  <span className="not-sr-only">|</span>{" "}
                  <OutboundLink
                    href="https://covid19.nj.gov/forms/error"
                    target="_blank"
                    rel="nofollow noopener noreferrer"
                    className="text-white"
                  >
                    Report a Correction
                    <span className="sr-only"> Link Opens in New Tab</span>
                  </OutboundLink>{" "}
                  <span className="not-sr-only">|</span>{" "}
                  <OutboundLink
                    href="https://www.nj.gov/nj/legal.html"
                    target="_blank"
                    rel="nofollow noopener noreferrer"
                    className="text-white"
                  >
                    Legal Statement
                    <span className="sr-only"> Link Opens in New Tab</span>
                  </OutboundLink>{" "}
                  <span className="not-sr-only">|</span>{" "}
                  <OutboundLink
                    href="https://www.nj.gov/nj/privacy.html"
                    target="_blank"
                    rel="nofollow noopener noreferrer"
                    className="text-white"
                  >
                    Privacy Policy
                    <span className="sr-only"> Link Opens in New Tab</span>
                  </OutboundLink>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container mx-auto py-10 px-4 sm:px-2 bg-black text-center text-xl">
          Made with{" "}
          <img
            src={heartImage}
            className="h-4 w-4 inline-block align-baseline"
            alt="Love"
          />{" "}
          by the{" "}
          <OutboundLink
            className="text-white no-underline hover:underline"
            href="https://innovation.nj.gov/"
            target="_blank"
            rel="nofollow noopener noreferrer"
          >
            NJ Office of Innovation
            <span className="sr-only"> Link Opens in New Tab</span>
          </OutboundLink>
        </div>
      </footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
